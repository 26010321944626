/****** Color Vars for Charts *********/
$teal-blue-color: #3f7589;
$prussian-blue-color: #172d47;
$french-raspberry-color: #bd3346;
$spanish-gray-color: #999999;
$gainsboro-color: #d9d9d9;

.dashboard {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.dashboard-header {
    margin: 6px;
    padding: 12px 6px 12px 6px;
    height: 100%;
    background-color: #FAFAFA;
}

.flex {
    display: flex;
}

.dashboard-header-text-small {
    font-size: 18px;
    margin: 0px;
    padding: 0px;
}

.dashboard-header-text-medium {
    font-size: 22px;
    margin: 0px;
    padding: 0px;
}

.dashboard-header-text-large {
    font-size: 28px;
    margin: 0px;
    padding: 0px;
}

.dashboard-header-text-oversize {
    font-size: 36px;
    margin: 0px;
    padding: 0px;
}

.fill-green {
    &:hover {
        background-color: green;
        border: 2px solid green;
    }
}

.fill-red {
    &:hover {
        background-color: red;
        border: 2px solid red;
    }
}

.active-event-dot {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: green;
    margin-right: 8px;
}

.tab-selector {
    font-size: 14px;
    padding: 8px 0px;
    width: 35% !important;
}

.tab-selected {
    border-bottom: 2px solid black;
    font-weight: bold;
}

.dashboard-warning {
    margin: 6px;
}

.dashboard-stats {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    margin: 6px;
    padding: 6px 6px 6px 6px;
}

.dashboard-stat-container {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    background-color: #FAFAFA;
}

.dashboard-chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
}

.donut-container {
    flex: 1;
    display: flex;
    justify-content: center;
    max-width: 200px;
}

.key-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 180px;
}

.key-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.key-info-container {
    display: flex;
    align-items: center;
    margin: 12px 0px;
}

.key-color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 12px;
}

.light-blue {
    background-color: #2A768A;
}

.dark-blue {
    background-color: #102D49;
}

.dark-red {
    background-color: #CD1F42;
}


.key-location {
    p {
        padding: 0px;
        margin: 0px;
    }
}

.stat-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.stat-header-text {
    font-size: 16px;
}

.stat-number {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 24px;
    font-weight: bold;
    font-family: sans-serif;
    color: rgb(250, 17, 79);
    margin-left: 12px;

    p {
        margin: 0px;
        padding: 0px;
    }
}

.form-stats {
    margin-top: 18px;
}

.stats-form-row {
    max-width: 200px;
    width: 200px;

    p {
        text-align: left;
        letter-spacing: .075em;
    }
}

.stats-form-input-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.stat-select {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    label {
        font-size: 16px;
        font-weight: normal;
        margin: 4px 0px;
    }

    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: none;
        -webkit-text-fill-color: rgb(250, 17, 79);
        -webkit-box-shadow: none;
        border-bottom: 2px solid rgb(250, 17, 79);
        -webkit-transition: background-color 5000s ease-in-out 0s;
        transition: background-color 5000s ease-in-out 0s;
    }

    select {
        max-width: 150px;
        min-width: 150px;
        margin: 0;
        padding: 6px 12px;
        font-size: 14px;
        font-weight: normal;
        color: rgb(250, 17, 79);
        border: 2px solid rgb(250, 17, 79);
        border-radius: 6px;
        background-color: #FFFFFF;
        box-shadow: none;
        -webkit-box-shadow: none;

        &:focus {
            border: 2px solid rgb(250, 17, 79);
            outline: none;
        }
    }
}

.checkin-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 2px solid black;
    border-radius: 6px;
    height: 40px;
    min-width: 310px;
    max-width: 310px;
    margin: 12px 6px;
    font-size: 16px;
    z-index: 1;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;

    &:hover {
        color: white;
    }
}

.event-name {
    font-size: 28px;
    margin: 6px 0px;
}

.event-info-wrapper {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 6px 0px;
}

.event-info {
    margin: 0px 0px 0px 12px;
}

.warning-event-headers {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.warning-event {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 6px 6px 6px 6px;
    margin: 0px 6px 18px 6px;
}

.warning-event-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
