.event {
    border: 1px solid black;
    border-radius: 4px;
    margin: 12px;
    padding: 12px;
}

.checkin-newuser-button {
    border: 1px solid black;
    margin: 6px;
    padding: 6px;
}