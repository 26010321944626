.app {
  height: 100%;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-content: center;
  overflow: hidden;
  max-height: 90vh;
  margin: 5vh 0;
}

.app-container {
  position: relative;
  max-width: 500px;
  width: 100%;
  background-color: white;
  overflow: hidden;
  border-radius: 10px;
  padding: 15px;
}

.main {
  height: calc(90vh - 160px);
  overflow-y: scroll;
}

.flexcenter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
}

.flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

@media (max-width: 500px) {
  .app-container {
    margin: 0 15px;
  }
}
