.container--usermanagement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin-bottom: 200px;
  height: 100%;
}

.edit-users {
  margin-top: 50px;
}

.results-heading {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.search-results {
  cursor: pointer;
  font-size: 14px;
}

.search-results-button {
  font-family: 'aliseoregular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  text-align: left;
}

.project-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.ua-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.search-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.user-display-column-left {
  display: flex;
  flex-direction: column;
  flex-basis: 15%;
  margin-bottom: 8px;
}
.user-display-column-right {
  display: flex;
  flex-direction: column;
  flex-basis: 85%;
  margin-bottom: 8px;
}

.user-is-active-column-left {
  display: flex;
  flex-direction: column;
  flex-basis: 15%;
}

.active-status {
  margin-right: 15px;
  width: 25px;
}

.is-active-flex {
  display: flex;
  align-items: center;
}

.button-remove {
  width: 25%;
  color: #fa114f;
  font-weight: bold;
}

.project-select {
  width: 250px;
  margin: 30px 0 0 0;
  font-size: 16px;
  font-weight: bold;
  color: darkblue;
  background-color: inherit;
  border: 3px solid black;
  border-radius: 0px;
}

.button-add {
  background-color: black;
  width: 250px;
  color: white;
  font-size: 20px;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button-back {
  background-color: black;
  width: 250px;
  color: white;
  font-size: 20px;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0px;
}

.tab-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  margin-bottom: 20px;
}

.select-button {
  background-color: #f1f1f1;
  width: 175px;
  color: #aaa;
  font-size: 16px;
  margin-top: 10px;
  margin: 5px;
  text-align: center;
}

.selected {
  border: 1px solid #333;
  color: #333;
}

.hide-results {
  display: none;
}

.light-results {
  color: #666;
}

.add-new-project {
  //nothing yet
}

.button {
  background-color: black;
  width: 250px;
  color: white;
  font-size: 20px;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.input-group {
  display: flex;
  flex-direction: column;
}

.validation-error {
  color: red;
  padding-top: 10px;
}

.project-success {
  color: green;
  padding-top: 10px;
}
