.hidden {
    display: none;
}

.event-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    margin-bottom: 200px;
}

