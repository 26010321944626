.new {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

.new-headers {
    max-width: 400px;
    margin-bottom: 4vh;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.future-list {
    max-width: 400px;
    z-index: 1;
    font-size: 1.4em;
}

.last-row {
    margin-top: 24px;
}

.success-info {
    font-size: 1.2em;
    max-width: 400px;
    z-index: 1;

    &:nth-child(2) {
        font-size: 1.6em;
    }
}

.rotated-success {
    position: absolute;
    background-color: white;
    width: 800px;
    margin-left: -12%;
    height: 700px;
    top: -10%;
    transform: rotate(-14deg);
    z-index: 0;
    overflow: hidden;
}

@media (max-width: 500px) {
    .rotated-success {
        position: absolute;
        background-color: white;
        width: 800px;
        margin-left: -12%;
        margin-top: -2%;
        height: 700px;
        transform: rotate(-8deg);
        z-index: 0;
        overflow: hidden;
    }

    .new-headers {
        margin-bottom: 2vh;
    }
}