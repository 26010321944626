.home {
    display: flex;
    flex-direction: column;
}

.rotated-home {
    position: absolute;
    background-color: white;
    width: 900px;
    margin-left: -12%;
    // margin-top: -6%;
    height: 600px;
    top: -20%;
    left: -10%;
    transform: rotate(-14deg);
    z-index: 0;
    overflow: hidden;
}

.home-buttons {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 80%;
    margin-top: 0px;
    margin-bottom: 2vh;
}

.home-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid black;
    border-radius: 6px;
    height: 40px;
    padding: 0px 6px;
    font-size: 18px;
    font-weight: bold;
    z-index: 1;
    transition: all .3s ease-in-out;
    margin-top: 12px;

    &:hover {
        background-color: black;
        color: white;
    }
}

.new-profile-button {
    margin-top: 36px;
    margin-bottom: 36px;
}

.home-button-light {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    margin-top: 4px;
    padding: 0px 2px;
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline;
    z-index: 1;
    transition: all .3s ease-in-out;
}

.disabled {
    border: 3px solid lightgray;
    color: lightgray;
    cursor: default;
    pointer-events: none;

    &:hover {
        background-color: white;
        color: lightgray;
    }
}

.home-headers {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 12px;
    margin-bottom: 2vh;
    padding-top: 6vh;
    z-index: 1;

    & h2 {
        max-width: 20px;
    }
}

.meeting-select-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-select-meeting {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 375px;
    max-width: 375px;

    .form-row {
        max-width: 350px;
        width: 350px;
        margin: 2px 0px;

        .form-input-select {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            margin: 2px;

            label {
                font-size: 18px;
                width: 350px !important;
                max-width: 350px !important;
                text-align: center;
            }
        }
    
        p {
            text-align: left;
            font-weight: bold;
            letter-spacing: .05em;
        }
    }
}

// .radio-buttons {
//     display: flex;
//     justify-content: space-around;
//     margin: 8px;
// }

.select-meeting-dropdown:-webkit-autofill,
.select-meeting-dropdown:-webkit-autofill:hover,
.select-meeting-dropdown:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: rgb(250, 17, 79);
  -webkit-box-shadow: none;
  border-bottom: 3px solid black;
  transition: background-color 5000s ease-in-out 0s;
}

.select-meeting-dropdown {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    padding: 12px; 
    font-size: 18px;
    font-weight: bold;
    color: rgb(250, 17, 79);
    background-color: inherit;
    border: 3px solid black;
    border-radius: 0px;
    margin: 6px 6px 0px 6px;

    option {
        padding: 100px;
    }

    &:focus {
        border: 3px solid rgb(250, 17, 79);
        outline: none;
    }
}

@media (max-width: 500px) {

    // .rotated-home {
    //     position: absolute;
    //     background-color: white;
    //     width: 800px;
    //     margin-left: -12%;
    //     margin-top: -4%;
    //     height: 300px;
    //     transform: rotate(-8deg);
    //     z-index: 0;
    //     overflow: hidden;
    // }

    .home-buttons {
        width: 90%;
    }
}